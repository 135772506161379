import './AboutMe.css'
import './General.css'
import { Button, Col, Image, Row } from 'react-bootstrap'

const AboutMe = () => {
    return (
        <div id='about-me' className='container-fluid general-container about-me-container'>
            <div className='general-jumbotron'>
                <Row>
                    <Col className='about-header'>About Me</Col>
                </Row>
                
                <Row>
                    <Col sm={3}>
                        <Image className='general-image image rounded-circle home-photo' src="/images/profile.jpg" alt='The site developer' fluid />
                    </Col>
                    <Col className='about-text'>
                        <p>
                            Jonathan Ferreira is currently employed as a software developer assisting in the creation of APIs and online architecture in the AWS cloud stack. He enjoys traveling the world and trying new cuisines.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className='about-text'>
                        <Button className='resume-button' href={require("../documents/resume.pdf").default} target='_blank' rel='noreferrer' download='jonathanferreira_resume.pdf'>Download Jonathan's Resume</Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default AboutMe;
