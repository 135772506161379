import './App.css';

import React, { Component, Fragment } from 'react';
import AboutMe from './containers/AboutMe';
import Contact from './containers/Contact';
import Games from './containers/Games';
// import Home from './containers/Home';
import NavBar from './containers/NavBar';
import Novels from './containers/Novels';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            games: [
                {
                    title: "Catch the Booty",
                    imagePath: "/images/catch_the_booty.png",
                    description: <span><i>Catch the Booty</i> is a pirate-themed arcade game developed by the indie studio Pocket Novellas. Released to Android and iOS devices. The game 
                    debuted at Gameacon in Atlantic City and received a warm reception. The game is no longer available, but originally released for Android devices.</span>,
                    links: []
                },
                {
                    title: "Pachinko Slaughterhouse",
                    imagePath: "images/pachinko.png",
                    description: <span><i>Pachinko Slaughterhouse</i> began its life as a three-month project under development at the now-defunct game studio, Among Elusions,
                    of which I was a founding member. Blending the popular Japanese arcade game and a humorous jab at factory 
                    farming, <i>Pachinko Slaughterhouse</i> was well received in the northeast United States, winning the 2015 Gameacon Crystal Award for fan favorite game. 
                    The game is no longer available, but originally released for Android devices.</span>,
                    links: [
                        {
                            path: "http://web.archive.org/web/20171007093553/https://www.gamasutra.com/view/pressreleases/305777/Pachinko_Slaughterhouse_App_Store_Release__By_AmongElusions.php",
                            text: "Press Release",
                            type: "site"
                        },
                        {
                            path: "https://www.dlh.net/en/gaming-news/56717/pachinko-slaughterhouse-should-be-a-hit-with-animal-right-activists.html",
                            text: "Another Press Release",
                            type: "site"
                        }
                    ]
                }
            ],
            novels: [
                {
                    title: "Into the Hive of Saarlathesh",
                    imagePath: "images/into_the_hive_cover.jpg",
                    description: <span>In 2012, in the midst of Hurricane Sandy assaulting the northeast United States, I began to develop a story. Over the span of 2013, that story would become a draft of a novel I entitled <i>Into the Hive of Saarlathesh</i>. I began a <a className='novels-links' href='https://www.kickstarter.com/projects/johnnytoxin/into-the-hive-of-saarlathesh' target='_blank' rel='noreferrer noopener'>Kickstarter campaign</a> to fund the book, and on January 17, 2014 I released my debut novel onto Amazon through their self-publishing arm.</span>,
                    linkButtons: [
                        {
                            link: "https://www.amazon.com/dp/B00I0WCUJE/ref=dp-kindle-redirect?_encoding=UTF8&btkr=1 kindle",
                            text: "Purchase a digital copy on Kindle."
                        },
                        {
                            link: "https://www.amazon.com/Into-Hive-Saarlathesh-Johnny-Toxin/dp/1492963003/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr=Paperback",
                            text: "Purchase a paperback copy on Amazon."
                        }
                    ]
                }
            ]
        }
    }

    render() {
        return (
            <Fragment>
                <NavBar />
                {/* <Home /> */}
                <AboutMe />
                <Games games={this.state.games}/>
                <Novels novels={this.state.novels}/>
                <Contact />
            </Fragment>
        )
    }
}

export default App;
